
import { Component, Vue } from "vue-property-decorator";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import Preloader from "@/components/Preloader.vue";

import logRoutes from "@/api/routes/logs";

interface ILogData {
  id: string;
  tag?: string;
  message: string;
  created_at: Date;
}

interface IPaginateLogData {
  current_page: number;
  logs: ILogData[];
  next_page: boolean;
}

@Component({
  components: {
    CInfiniteScroll,
    Preloader
  }
})
export default class VLogs extends Vue {
  protected preload = false;
  protected preload_infinite = false;

  protected per_page: number = 30;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected logs: ILogData[] = [];

  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected watchers: Function[] = [];

  protected async created() {
    if (!this.currentUser?.is_admin) {
      alert("У вас нет доступа к этой странице.");
      await this.$router.push({ name: "root" });
    }

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;

          if (!this.currentUser?.is_admin) {
            alert("У вас нет доступа к этой странице.");
            this.$router.push({ name: "root" });
          }
        }
      )
    );

    await this.clearLogs();
  }

  protected async clearLogs() {
    this.current_page = 0;
    this.logs = [];

    await this.loadLogs();
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadLogs(this.current_page + 1);
    }
  }

  protected async loadLogs(page: number = 0) {
    this.preload = false;
    this.preload_infinite = false;
    return this.$api
      .get(logRoutes.logs, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IPaginateLogData }) => {
        this.logs.push(...res.logs);
        this.current_page = res.current_page;
        this.next_page = res.next_page;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
        this.preload = false;
      });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}
